import { extendTheme } from "@chakra-ui/react";
import { withProse } from "@nikolovlazar/chakra-ui-prose";

const theme = extendTheme(
  {
    fonts: {
      heading: "Avenir, sans-serif",
      body: "Avenir, sans-serif",
    },
  },
  withProse({
    baseStyle: {
      a: {
        color: "#800020",
        textDecoration: "underline",
      },
    },
  })
);

export default theme;
