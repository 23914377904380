import React from "react";
import { Box, Flex, Button, Link, Icon } from "@chakra-ui/react";
import { FaAddressCard, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

const ContactUsSection = () => {
  return (
    <Box
      py={10}
      px={4}
      textAlign="center"
      bgGradient="linear(to-b, #F2E5E9, #FFFFFF)"
    >
      <Flex justify="center" align="center" flexWrap="wrap" mt={7} mb={7}>
        <Link href={`mailto:invest@innovateafricafund.com`} isExternal>
          <Button
            bg="#800020"
            color={"white"}
            size={{ base: "full", md: "md" }}
            m={2}
            leftIcon={<Icon as={FaEnvelope} />}
            width="300px"
          >
            invest@innovateafricafund.com
          </Button>
        </Link>

        <Link href="#" isExternal>
          <Button
            bg="#800020"
            color={"white"}
            size={{ base: "full", md: "md" }}
            m={2}
            leftIcon={<Icon as={FaPhoneAlt} />}
          >
            Call Us
          </Button>
        </Link>

        <Button
          bg="#800020"
          color={"white"}
          size={{ base: "full", md: "md" }}
          m={2}
          leftIcon={<Icon as={FaAddressCard} />}
          minW="unset"
          width="fit-content"
        >
          1 KN78 Kigali City, Rwanda
        </Button>
      </Flex>
    </Box>
  );
};

export default ContactUsSection;
