import React, { useEffect, useState } from "react";
import { getAllBlogPosts } from "../../service/blog";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { Prose } from "@nikolovlazar/chakra-ui-prose";
import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  Heading,
  HStack,
  Link,
  Center,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import "../../index.css";
import "../../styles/fonts.css";
import { useSearchParams } from "react-router-dom";

const Blog = () => {
  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams({ page: "1" })
  );
  const [posts, setPosts] = useState({
    data: [],
    meta: {
      pagination: { page: 1, pageCount: 1, pageSize: 25, total: 1 },
    },
  });
  const blogPosts = posts.data;
  const activeFilter = searchParams.get("category");
  const page = searchParams.get("page");

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await getAllBlogPosts(
          (activeFilter?.trim()?.length ?? 0) > 1
            ? `filters[BlogCategory][$eq]=${activeFilter}&pagination[page]=${page}`
            : `pagination[page]=${page}`
        );
        // const posts = response.data;
        // console.log("Blog Posts:", response);
        setPosts(response);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      }
    };

    fetchBlogPosts();
  }, [activeFilter, page]);

  const handleFilterClick = (filter) => {
    setSearchParams((i) => {
      i.set("page", "1");
      i.set("category", filter);
      return i;
    });
  };

  const truncateContent = (content, maxLength) => {
    if (!content || content.length === 0) {
      return "";
    }

    const paragraph = content[0].children[0];
    if (paragraph.type === "text") {
      const text = paragraph.text;
      return text.length <= maxLength
        ? text
        : text.substring(0, maxLength) + "...";
    }

    return "";
  };

  // console.log(Object.fromEntries(searchParams));
  // console.log(posts);

  return (
    <div>
      <title>Innovate Africa Fund</title>
      <div>
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="dropdown">
            <select name="filter" id="">
              <option value="">Select a filter</option>
              <option value="">Enterprise</option>
              <option value="">Fintech</option>
              <option value="">Games</option>
              <option value="">General</option>
              <option value="">Growth</option>
              <option value="">Talent x Opportunity</option>
            </select>
          </div>
        </div> */}

        <HStack
          spacing={7}
          overflowX="auto"
          scrollbarWidth="thin"
          css={{ "&::-webkit-scrollbar": { height: "8px" } }}
          pl={20}
          pr={5}
          pt={10}
          pb={{ base: 10, md: 20 }}
        >
          <Box maxW={"150px"} flexShrink={0}>
            <h4>Explore Specific Categories</h4>
          </Box>
          <Box
            borderBottom={activeFilter === "" ? "4px solid #800020" : "none"}
          >
            <Button
              // href="#"
              onClick={() => handleFilterClick("")}
              color={activeFilter === "" ? "#800020" : "black"}
              _hover={{ color: "#800020" }}
            >
              <Text>All</Text>
            </Button>
          </Box>
          {[
            "African News",
            "Employment",
            "Health",
            "Security",
            "Talent",
            "Opportunities",
            "Enterprise",
            "Fintech",
            "Games",
            "General",
            "Growth",
          ].map((filter) => (
            <Box
              key={filter}
              borderBottom={
                activeFilter === filter ? "4px solid #800020" : "none"
              }
            >
              <Button
                // href="#"
                onClick={() => handleFilterClick(filter)}
                color={activeFilter === filter ? "#800020" : "black"}
                _hover={{ color: "#800020" }}
              >
                <Text>{filter}</Text>
              </Button>
            </Box>
          ))}
        </HStack>

        <Center flexDirection="column">
          <div className="articles">
            {blogPosts.map((post) => (
              // console.log("HeaderImage:", post.HeaderImage),
              // (activeFilter === "" ||
              //   post.BlogCategory === activeFilter) &&
              <Flex
                key={post.id}
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "start", md: "center" }}
                width="100%"
                mb={{ base: 4, md: 7 }}
                gap={{ base: 0, md: 20 }}
              >
                {post.HeaderImage && post.HeaderImage && post.HeaderImage && (
                  <Image
                    src={post.HeaderImage.url}
                    alt="Blog Image"
                    w={{ base: "100%", md: "40%" }}
                    mb={{ base: 4, md: 0 }}
                  />
                )}
                {/* <Image src={post.HeaderImage} alt="Blog Image" w={{ base: '100%', md: '30%' }} mb={{ base: 4, md: 0 }} /> */}
                <Box ml={{ base: 0, md: 4 }} flex={{ base: "none", md: 1 }}>
                  <Heading as="h3" mb={2}>
                    {post.Title}
                  </Heading>
                  <Text as="h5" mb={2}>
                    {post.AuthorsName}
                  </Text>
                  <Prose>
                    {truncateContent(post.BlogContent, 120)}
                    {/* <BlocksRenderer content={post.BlogContent} /> */}
                  </Prose>
                  <a href={`/blog/${post.Slug}/`}>
                    <Button
                      bgColor="white"
                      color="#800020"
                      borderColor="#800020"
                      borderRadius={0}
                      mt={4}
                      rightIcon={<ChevronRightIcon />}
                    >
                      See More
                    </Button>
                  </a>
                  <hr style={{ height: 2 }} />
                </Box>
              </Flex>
            ))}
          </div>

          <Box display="flex" gap="4" mb="10">
            {posts.meta.pagination.page > 1 && (
              <Link
                href={`/blog?page=${Math.max(
                  1,
                  Number(posts.meta.pagination.page) - 1
                )}${activeFilter ? `&category=${activeFilter}` : ""}`}
                border={"1px solid currentColor"}
                color="#800020"
                px={4}
                py={2}
              >
                Previous
              </Link>
            )}
            {posts.meta.pagination.page < posts.meta.pagination.pageCount && (
              <Link
                href={`/blog?page=${Math.min(
                  Number(posts.meta.pagination.pageCount),
                  Number(posts.meta.pagination.page) + 1
                )}${activeFilter ? `&category=${activeFilter}` : ""}`}
                border={"1px solid currentColor"}
                color="#800020"
                px={4}
                py={2}
              >
                Next
              </Link>
            )}
          </Box>
        </Center>
      </div>
    </div>
  );
};

export default Blog;
