import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { Prose } from "@nikolovlazar/chakra-ui-prose";
import {
  Box,
  Image,
  Text,
  Heading,
  HStack,
  Link,
  Center,
} from "@chakra-ui/react";
import { getBlogPostBySlug, getBlogPostByTitle } from "../../service/blog";
import "../../styles/fonts.css";

const FullArticle = () => {
  const { articleSlug } = useParams();
  const [fullArticle, setFullArticle] = useState(null);
  const [activeFilter, setActiveFilter] = useState("");

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  useEffect(() => {
    const fetchFullArticle = async () => {
      try {
        const response = await getBlogPostBySlug(articleSlug);
        console.log("API Response:", response);
        const post = response?.data;
        setFullArticle(post);
      } catch (error) {
        console.error("Error fetching full article:", error);
      }
    };

    fetchFullArticle();
  }, [articleSlug]);

  if (!fullArticle) {
    return <div>Loading...</div>;
  }

  const selectedArticle = fullArticle[0];

  if (!selectedArticle) {
    return <div>Article not found</div>;
  }

  return (
    <div>
      <HStack
        spacing={7}
        overflowX="auto"
        scrollbarWidth="thin"
        css={{ "&::-webkit-scrollbar": { height: "8px" } }}
        pl={20}
        pr={5}
        pt={10}
        pb={{ base: 10, md: 20 }}
      >
        <h4>Explore Specific Categories</h4>
        {[
          "African News",
          "Employment",
          "Health",
          "Security",
          "Talent",
          "Opportunities",
          "Enterprise",
          "Fintech",
          "Games",
          "General",
          "Growth",
        ].map((filter) => (
          <Box
            key={filter}
            borderBottom={
              activeFilter === filter ? "4px solid #800020" : "none"
            }
          >
            <Link
              href="#"
              onClick={() => handleFilterClick(filter)}
              color={activeFilter === filter ? "#800020" : "black"}
              _hover={{ color: "#800020" }}
            >
              <Text>{filter}</Text>
            </Link>
          </Box>
        ))}
      </HStack>

      <Box
        mx={{ base: 5, md: 20 }}
        flex={{ base: "none", md: 1 }}
        textAlign="left"
      >
        {selectedArticle.HeaderImage &&
          selectedArticle.HeaderImage &&
          selectedArticle.HeaderImage && (
            <Box
              overflow="hidden"
              borderRadius="md"
              maxHeight={{ base: "300px", md: "500px" }}
            >
              <Image
                src={selectedArticle.HeaderImage.url}
                alt="Blog Image"
                w={{ base: "100%", md: "100%" }}
                mb={{ base: 4, md: 7 }}
              />
            </Box>
          )}
        <Heading as="h3" mb={2} mt={7}>
          {selectedArticle.Title}
        </Heading>
        <Text as="h5" mb={2}>
          {selectedArticle.AuthorsName}
        </Text>
        <Prose style={{ lineHeight: "2.0" }}>
          <BlocksRenderer content={selectedArticle.BlogContent} />
        </Prose>
      </Box>
    </div>
  );
};

export default FullArticle;
