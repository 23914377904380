import { Box, Button, Heading, Link, Text } from "@chakra-ui/react";
import { Prose } from "@nikolovlazar/chakra-ui-prose";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import eventImage from "../../images/event.png"; // Placeholder image
import { getEventByID } from "../../service/eventsapi";
import { CalendarIcon, TimeIcon } from "@chakra-ui/icons";

const EventDetail = () => {
  const { eventId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [event, setEvent] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getEventByID(eventId)
      .then((r) => {
        // console.log(r);
        setEvent(r.data);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [eventId]);

  if (!event && isLoading) {
    return <Text>Loading...</Text>;
  }

  if (!event && !isLoading) {
    return <Text>Event not found</Text>;
  }

  const duration = Boolean(event.EndDate)
    ? getDurationBetweenDates(event.StartDate, event.EndDate)
    : "1 day";

  // Format date in a readable form
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZoneName: "short",
    };
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <Box maxW="1024px" mx="auto" p={4}>
      {/* Event Image */}
      <img
        src={event.Image?.url ?? eventImage}
        alt="Event"
        style={{ width: "100%", borderRadius: "8px" }}
      />

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="start"
        gap={8}
        mt={6}
      >
        <div>
          <Heading size="xl" fontWeight="normal">
            {event.Title}
          </Heading>

          <Box
            mt={4}
            px={4}
            py={4}
            boxShadow={"3px 6px 20px -3px rgba(0,0,0,0.33);"}
          >
            <Heading as="h3" fontSize="lg" mb={2} color="#800020">
              Date and Time
            </Heading>
            <Box display="flex" gap={4}>
              <CalendarIcon color="#800020" fontSize="lg" />
              <Text fontSize="sm" flex={1} color="#800020">
                {formatDate(event.StartDate)} - {formatDate(event.EndDate)}
              </Text>
            </Box>
          </Box>
        </div>

        {/* Reserve Spot Button */}

        <div>
          <Box display="flex" gap={2} mb={2}>
            <Text fontSize="sm">Price:</Text>{" "}
            <Text fontSize="sm" fontWeight="bold">
              {!event.Price || event.Price === 0
                ? "Free"
                : new Intl.NumberFormat(undefined, {
                    currency: event.Currency ?? "NGN",
                    currencyDisplay: "symbol",
                    style: "currency",
                  }).format(event.Price)}
            </Text>
          </Box>
          <Link
            as="a"
            href={event.ReserveSpotURL}
            target="_blank"
            rel="noopener noreferrer"
            display="block"
            bgColor="#800020"
            color="white"
            px={6}
            py={3}
            border="none"
            fontWeight={"600"}
            rounded="md"
          >
            Reserve Spot
          </Link>
        </div>
      </Box>

      {/* Event Duration and Description */}
      <Box mt={6}>
        <Heading as="h3" fontSize="lg">
          Event Details
        </Heading>
        <Box display="flex" gap={4} my={4} alignItems="center">
          <TimeIcon fontSize="lg" color="green" />
          <Text fontSize="sm" fontWeight="bold">
            {duration}
          </Text>
        </Box>
        <Prose style={{ lineHeight: "2.0" }}>
          <BlocksRenderer content={event.Description} />
        </Prose>
      </Box>
    </Box>
  );
};

export default EventDetail;

function getDurationBetweenDates(date1, date2) {
  const msInHour = 1000 * 60 * 60;
  const msInDay = msInHour * 24;

  const differenceInMs = new Date(date2) - new Date(date1);

  let value, unit;
  if (Math.abs(differenceInMs) >= msInDay) {
    value = Math.round(differenceInMs / msInDay);
    unit = "day";
  } else {
    value = Math.round(differenceInMs / msInHour);
    unit = "hour";
  }

  // Format without prefix using only the value and unit
  return `${Math.abs(value)} ${unit}${Math.abs(value) !== 1 ? "s" : ""}`;
}
