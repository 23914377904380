import React from "react";
import { Box, Container, Flex, Heading, Text, Image } from "@chakra-ui/react";
import HomeImage2 from "../../images/support-grp.png";
import "../../styles/fonts.css";

const FlexSection = () => {
  return (
    <Container maxW="none" pt={120} pb={100}>
      <Flex
        direction={{ base: "column-reverse", md: "row" }}
        align="center"
        justify="space-between"
        gap="30px"
        px={{ base: 0, md: 130 }}
      >
        <Image
          src={HomeImage2}
          alt="Flex Section Image"
          boxSize={{ base: "100%", md: "40%" }}
          borderRadius="md"
          loading="lazy"
        />

        <Box
          flex="1"
          textAlign={{ base: "center", md: "left" }}
          ml={{ base: 0, md: 8 }}
          mb={{ base: 8, md: 0 }}
        >
          <Heading as="h2" size="2xl" color="#51101C" mb={50}>
            Empowering Founders to Solve Wicked Problems
          </Heading>
          <Text color="#312B2D" fontsize="20">
            We’re building a high-conviction early-stage investment vehicle
            leveraging signal investment trends to route capital towards a
            high-potential technology venture. Innovate founders will harness
            the bottom-line benefits of the product operating model to deliver
            value to customers through sticky products that disrupt with
            technology. Our decisions will determine the future of who builds,
            owns and benefits from the next wave of disruptive technology across
            emerging markets.{" "}
          </Text>
        </Box>
      </Flex>
    </Container>
  );
};

export default FlexSection;
