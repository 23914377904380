import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Flex,
  Image,
  Center,
} from "@chakra-ui/react";
import Headline1 from "../../images/dollar.png";
import Headline2 from "../../images/productmodel.png";
// import Headline3 from "../../images/headlines3.png";
// import Headline4 from "../../images/headlines4.png";
import NetworkingImg from "../../images/event.png";
import revuenueImg from "../../images/books.jpeg";
import operationalImg from "../../images/operation.png";
import pmfImg from "../../images/man.png";

const AboutUs = () => {
  return (
    <Container maxW="none" p={0} marginX={0}>
      <Center>
        <Box py={6} px={14}>
          <Heading as="h2" size="xl" color="#000000" mb={50} textAlign="center">
            The Innovate Africa Fund is a founder-first catalyst fund dedicated
            to accelerating the journey to product-market fit for African
            entrepreneurs.
          </Heading>
          <Heading as="h2" size="md" color="#312B2D" mb={4} textAlign="center">
            We are committed to providing the necessary infrastructure and
            support that empower founders to tackle audacious problems and
            unlock growth. Our mission is to nurture and invest in
            high-potential technology ventures, driving innovation and economic
            development across the continent.
          </Heading>
        </Box>
      </Center>

      <Heading as="h2" size="2xl" textAlign="center" color="#801423">
        What We Offer
      </Heading>

      <Flex
        direction={{ base: "column", md: "column" }}
        align="center"
        justify="space-around"
        py={10}
      >
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          textAlign={{ base: "center", md: "right" }}
          mt={{ base: 8, md: 0 }}
          alignItems="center"
          justifyContent="space-between"
          backgroundColor={"#FEF7F9"}
          py={10}
          px={{ base: 10, md: 40 }}
          maxWidth={{ base: "100%", md: "100%" }}
        >
          <Box ml={{ base: 0, md: 8 }} maxWidth={{ base: "100%", md: "45%" }}>
            <Heading
              as="h3"
              fontSize={{ base: "xl", md: "2xl" }}
              color="#51101C"
              mt={4}
              mb={4}
              textAlign={"left"}
            >
              First Cheque Fund
            </Heading>
            <Text
              fontSize={{ base: "md", md: "lg" }}
              color="#312B2D"
              textAlign={"left"}
            >
              We provide initial capital to high-potential early-stage
              technology ventures, enabling them to kickstart their operations,
              build prototypes, and gain early traction. This funding is crucial
              for startups to validate their ideas and attract further
              investment.
            </Text>
          </Box>
          <Image
            src={Headline1}
            alt="Service 1 Image"
            boxSize={{ base: "100%", md: "30%" }}
            objectFit="cover"
            pb={{ base: 4, md: 4 }}
            w={{ base: 300, md: 300 }}
            h={{ base: 250, md: 250 }}
          />
        </Box>

        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          textAlign={{ base: "center", md: "right" }}
          mt={{ base: 8, md: 0 }}
          alignItems="center"
          justifyContent="space-between"
          py={10}
          px={{ base: 10, md: 40 }}
        >
          <Image
            src={Headline2}
            alt="Service 2 Image"
            boxSize={{ base: "100%", md: "30%" }}
            objectFit="cover"
            loading="lazy"
            mb={{ base: 4, md: 0 }}
            w={{ base: 300, md: 300 }}
            h={{ base: 250, md: 250 }}
          />
          <Box ml={{ base: 0, md: 8 }} maxWidth={{ base: "100%", md: "45%" }}>
            <Heading
              as="h3"
              fontSize={{ base: "xl", md: "2xl" }}
              color="#51101C"
              mt={4}
              mb={4}
              textAlign={"left"}
            >
              Product Operating Model
            </Heading>
            <Text
              fontSize={{ base: "md", md: "lg" }}
              color="#312B2D"
              textAlign={"left"}
            >
              We leverage innovative product strategies to deliver value and
              create disruptive, customer-centric solutions. This model focuses
              on understanding customer needs, iterating on product features,
              and ensuring a seamless user experience that drives customer
              loyalty and market differentiation
            </Text>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          textAlign={{ base: "center", md: "right" }}
          mt={{ base: 8, md: 0 }}
          alignItems="center"
          justifyContent="space-between"
          backgroundColor={"#FEF7F9"}
          py={10}
          px={{ base: 10, md: 40 }}
          maxWidth={{ base: "100%", md: "100%" }}
        >
          <Box ml={{ base: 0, md: 8 }} maxWidth={{ base: "100%", md: "45%" }}>
            <Heading
              as="h3"
              fontSize={{ base: "xl", md: "2xl" }}
              color="#51101C"
              mt={4}
              mb={4}
              textAlign={"left"}
            >
              Networking & Partnerships
            </Heading>
            <Text
              fontSize={{ base: "md", md: "lg" }}
              color="#312B2D"
              textAlign={"left"}
            >
              We connect founders with a network of industry leaders, investors,
              and other stakeholders to foster collaboration and growth. These
              partnerships will provide startups with access to valuable
              resources, mentorship, and opportunities for strategic alliances
              that can accelerate their development.
            </Text>
          </Box>
          <Image
            src={NetworkingImg}
            alt="Service 3 Image"
            boxSize={{ base: "100%", md: "30%" }}
            objectFit="cover"
            loading="lazy"
            pb={{ base: 4, md: 4 }}
            w={{ base: 300, md: 300 }}
            h={{ base: 250, md: 250 }}
          />
        </Box>

        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          textAlign={{ base: "center", md: "right" }}
          mt={{ base: 8, md: 0 }}
          alignItems="center"
          justifyContent="space-between"
          py={10}
          px={{ base: 10, md: 40 }}
        >
          <Image
            src={revuenueImg}
            alt="Service 4 Image"
            boxSize={{ base: "100%", md: "30%" }}
            objectFit="cover"
            loading="lazy"
            mb={{ base: 4, md: 0 }}
            w={{ base: 300, md: 300 }}
            h={{ base: 250, md: 250 }}
          />
          <Box ml={{ base: 0, md: 8 }} maxWidth={{ base: "100%", md: "45%" }}>
            <Heading
              as="h3"
              fontSize={{ base: "xl", md: "2xl" }}
              color="#51101C"
              mt={4}
              mb={4}
              textAlign={"left"}
            >
              Revenue Model Iteration
            </Heading>
            <Text
              fontSize={{ base: "md", md: "lg" }}
              color="#312B2D"
              textAlign={"left"}
            >
              We assist startups in refining and optimising their revenue models
              for sustainable growth and profitability. This service includes
              analysing market dynamics, testing different pricing strategies,
              and identifying the most effective revenue streams to ensure
              long-term financial stability.
            </Text>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          textAlign={{ base: "center", md: "right" }}
          mt={{ base: 8, md: 0 }}
          alignItems="center"
          justifyContent="space-between"
          backgroundColor={"#FEF7F9"}
          py={10}
          px={{ base: 10, md: 40 }}
          maxWidth={{ base: "100%", md: "100%" }}
        >
          <Box ml={{ base: 0, md: 8 }} maxWidth={{ base: "100%", md: "45%" }}>
            <Heading
              as="h3"
              fontSize={{ base: "xl", md: "2xl" }}
              color="#51101C"
              mt={4}
              mb={4}
              textAlign={"left"}
            >
              Operations and Governance Advisory
            </Heading>
            <Text
              fontSize={{ base: "md", md: "lg" }}
              color="#312B2D"
              textAlign={"left"}
            >
              We offer expert advice on efficient operations and sound
              governance practices to ensure robust business foundations. This
              includes guidance on organisational structure, compliance, risk
              management, and operational efficiencies that support scalable and
              sustainable growth.
            </Text>
          </Box>
          <Image
            src={operationalImg}
            alt="Service 3 Image"
            boxSize={{ base: "100%", md: "30%" }}
            objectFit="cover"
            loading="lazy"
            pb={{ base: 4, md: 4 }}
            w={{ base: 300, md: 300 }}
            h={{ base: 250, md: 250 }}
          />
        </Box>

        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          textAlign={{ base: "center", md: "right" }}
          mt={{ base: 8, md: 0 }}
          alignItems="center"
          justifyContent="space-between"
          py={10}
          px={{ base: 10, md: 40 }}
        >
          <Image
            src={pmfImg}
            alt="Service 4 Image"
            boxSize={{ base: "100%", md: "30%" }}
            objectFit="cover"
            loading="lazy"
            mb={{ base: 4, md: 0 }}
            w={{ base: 300, md: 300 }}
            h={{ base: 250, md: 250 }}
          />
          <Box ml={{ base: 0, md: 8 }} maxWidth={{ base: "100%", md: "45%" }}>
            <Heading
              as="h3"
              fontSize={{ base: "xl", md: "2xl" }}
              color="#51101C"
              mt={4}
              mb={4}
              textAlign={"left"}
            >
              Accelerate Path to PMF
            </Heading>
            <Text
              fontSize={{ base: "md", md: "lg" }}
              color="#312B2D"
              textAlign={"left"}
            >
              We guide startups in quickly achieving a strong product-market
              fit, crucial for scaling and long-term success. This involves
              market research, customer feedback, and iterative development to
              align the product with market demands, ensuring it meets the needs
              of the target audience and stands out in the competitive
              landscape.
            </Text>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};

export default AboutUs;
